import React from 'react'
import clsx from 'clsx'

import * as styles from './date.module.scss'

type Props = React.ComponentProps<'p'>

export const Date = ({ className, children, ...props }: Props) => (
  <p className={clsx(styles.date, className)} {...props}>
    {children}
  </p>
)
