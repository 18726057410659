import React, { useCallback, useEffect, useRef } from 'react'
import clsx from 'clsx'
import { ContentfulLink } from '../contentful-link'
import { LinkButton } from '../button'
import { Menu } from '@headlessui/react'
import { ArrowIcon } from '../icons'

import * as styles from './header-navigation.module.scss'
import { useActiveParentId } from './useActiveParentId'

type Props = React.ComponentProps<'nav'> & {
  path: string
  brand: Queries.BrandFragment
  items?: Queries.MenuHeaderTreeFragment['items']
  close?: () => void
}

export const HeaderNavigation = ({
  className,
  path,
  brand,
  items,
  ...props
}: Props) => {
  const listRef = useRef<HTMLUListElement>(null)

  /**
   * Find active parent whose child matches page slug.
   */
  const activeParentId = useActiveParentId({ path, items })

  /**
   * Track touch capability to disable mouseenter/mouseleave logic
   */
  const isTouch = useRef(false)
  useEffect(() => {
    document.addEventListener(
      'touchstart',
      () => {
        isTouch.current = true
      },
      {
        once: true,
      }
    )
  }, [])

  /**
   * Open/close dropdown on mouseenter/mouseleave
   */
  const handleMouseEnter = useCallback<React.MouseEventHandler<HTMLLIElement>>(
    (e) => {
      if (isTouch.current) return
      const btn = e.currentTarget.querySelector('button')
      if (btn?.getAttribute('aria-expanded') !== 'true') {
        btn?.click()
      }
    },
    []
  )
  const handleMouseLeave = useCallback<React.MouseEventHandler<HTMLLIElement>>(
    (e) => {
      if (isTouch.current) return
      const btn = e.currentTarget.querySelector('button')
      if (btn?.getAttribute('aria-expanded') === 'true') {
        btn?.click()
      }
    },
    []
  )

  return (
    <nav className={clsx(styles.nav, className)} {...props}>
      <ul className={styles.list} ref={listRef}>
        {items?.map((item) => (
          <li
            key={item?.id}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            {!item?.items ? (
              <ContentfulLink
                className={styles.link}
                activeClassName={styles.activeLink}
                link={item}
              >
                <span>{item?.title}</span>
              </ContentfulLink>
            ) : (
              <Menu>
                {({ open, close }) => (
                  <>
                    <Menu.Button
                      className={clsx(
                        styles.link,
                        open && styles.activeButton,
                        activeParentId === item.id && styles.activeParent
                      )}
                    >
                      <span>{item.title}</span>
                      <ArrowIcon />
                    </Menu.Button>
                    <Menu.Items
                      as="ul"
                      className={styles.submenu}
                      unmount={false}
                    >
                      {item.items!.map((subItem) => (
                        <Menu.Item
                          as="li"
                          key={subItem?.id}
                          className={styles.submenuItem}
                        >
                          <ContentfulLink
                            link={subItem}
                            activeClassName={styles.activeLink}
                            className={styles.submenuLink}
                            onClick={close}
                          >
                            <span>{subItem?.title}</span>
                          </ContentfulLink>
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </>
                )}
              </Menu>
            )}
          </li>
        ))}
        <li className={styles.dappButton}>
          <LinkButton
            href={brand?.dAppUrl!}
            color="primary"
            variant="fill"
            target="_blank"
          >
            {brand?.dAppText}
          </LinkButton>
        </li>
      </ul>
    </nav>
  )
}
