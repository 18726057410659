import React from 'react'

type Props = React.ComponentProps<'svg'>

export const MenuIcon = (props: Props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x="2" y="4" width="14" height="2" />
    <rect x="2" y="11" width="20" height="2" />
    <rect x="8" y="18" width="14" height="2" />
  </svg>
)
