import { useMemo } from 'react'

type Props = {
  path: string
  items?: Queries.MenuHeaderTreeFragment['items']
}

export const useActiveParentId = ({ path, items }: Props) =>
  useMemo(() => {
    if (!items) return null
    for (let i = 0; i < items.length; i++) {
      const item = items[i]
      const subItems = item?.items
      if (subItems?.length) {
        for (let j = 0; j < subItems.length; j++) {
          const subItem = subItems[j]
          if (`/${subItem?.page?.slug}/` === path) {
            return item?.id
          }
        }
      }
    }
    return null
  }, [path, items])
