import React, { createContext, useContext } from 'react'

export type Snippets = { [key: string]: string }

export const SnippetsContext = createContext<Snippets>({})

type Props = {
  children: React.ReactNode
  snippets?: Snippets
}

export const SnippetsProvider = ({ children, snippets = {} }: Props) => {
  return (
    <SnippetsContext.Provider value={snippets}>
      {children}
    </SnippetsContext.Provider>
  )
}

export const useSnippets = () => {
  const context = useContext(SnippetsContext)
  if (!context) {
    throw new Error('Missing Snippets Context!')
  }
  return context
}
