/**
 * Send google analytics page_view event
 *
 * @see src/components/cookie-consent.tsx
 * @see https://paulie.dev/posts/2022/07/how-to-use-gatsbys-script-api-with-google-analytics/
 */
export const onRouteUpdate = ({ location: l }) => {
  // skip when not in production
  if (process.env.NODE_ENV !== 'production') {
    return null
  }
  const page_path = l ? l.pathname + l.search + l.hash : undefined
  setTimeout(() => {
    if (typeof window.gtag === 'function') {
      // send page view to google tagmanager
      window.gtag('event', 'page_view', { page_path })
    }
  }, 100)
  return true
}
