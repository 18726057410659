import React from 'react'

type Props = React.ComponentProps<'svg'>

export const FacebookIcon = (props: Props) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.8687 1.03125H2.13125C1.52374 1.03125 1.03125 1.52374 1.03125 2.13125V19.8687C1.03125 20.4763 1.52374 20.9688 2.13125 20.9688H11.6875V13.2584H9.09219V10.2403H11.6875V8.01969C11.6875 5.445 13.2619 4.0425 15.5581 4.0425C16.3319 4.04081 17.1053 4.07982 17.875 4.15938V6.85094H16.2938C15.0425 6.85094 14.7984 7.44219 14.7984 8.31531V10.2369H17.7891L17.4006 13.255H14.7812V20.9688H19.8687C20.4763 20.9688 20.9688 20.4763 20.9688 19.8687V2.13125C20.9688 1.52374 20.4763 1.03125 19.8687 1.03125Z"
      fill="currentColor"
    />
  </svg>
)
