import React from 'react'

type Props = React.ComponentProps<'svg'>

export const CloseIcon = (props: Props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x="4.22168"
      y="18.364"
      width="20"
      height="2"
      transform="rotate(-45 4.22168 18.364)"
    />
    <rect
      x="5.63574"
      y="4.2218"
      width="20"
      height="2"
      transform="rotate(45 5.63574 4.2218)"
    />
  </svg>
)
