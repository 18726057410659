import React from 'react'

type Props = React.ComponentProps<'svg'>

export const LinkIcon = (props: Props) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.4328 2.41076C14.3429 2.46301 13.2948 2.93845 12.4846 3.74826L9.30634 6.92482C9.80555 6.42325 11.6586 6.76808 12.1082 7.21739L14.0319 5.29474C14.4579 4.86894 14.9911 4.60771 15.5373 4.5842C15.9085 4.56591 16.4338 4.64428 16.8964 5.10666C17.3277 5.53769 17.4192 6.03925 17.4192 6.38146C17.4192 6.95355 17.1578 7.52303 16.7083 7.96973L13.3628 11.3344C12.5212 12.1755 11.2405 12.2435 10.4982 11.5016C10.0748 11.0784 9.37691 11.0758 8.95088 11.5016C8.52485 11.9274 8.52485 12.6222 8.95088 13.048C9.71407 13.8108 10.7177 14.1975 11.7527 14.1975C12.8714 14.1975 14.0136 13.7403 14.8891 12.86L18.2556 9.51621C19.1102 8.66461 19.5938 7.52564 19.5938 6.38146C19.5938 5.31825 19.1912 4.30729 18.4437 3.56018C17.644 2.76081 16.5697 2.35852 15.4328 2.41076ZM10.2473 7.80255C9.12861 7.80255 7.96814 8.26231 7.08995 9.14004L3.74445 12.4838C2.88978 13.3354 2.40625 14.4744 2.40625 15.6185C2.40625 16.6817 2.80876 17.6927 3.55627 18.4398C4.35605 19.2392 5.43027 19.6415 6.56721 19.5892C7.65711 19.537 8.7052 19.0616 9.51543 18.2517L12.6937 15.0752C12.1918 15.5767 10.3414 15.2319 9.8918 14.7826L7.96814 16.7053C7.54211 17.1311 7.00892 17.3897 6.46267 17.4158C6.09153 17.4341 5.56618 17.3557 5.10356 16.8933C4.6723 16.4623 4.58082 15.9581 4.58082 15.6185C4.58082 15.0464 4.84219 14.477 5.29174 14.0303L8.63724 10.6656C9.47884 9.82447 10.7595 9.75916 11.5018 10.4984C11.9279 10.9242 12.6257 10.9242 13.0491 10.4984C13.4751 10.0726 13.4751 9.37776 13.0491 8.95196C12.2859 8.18917 11.2797 7.80255 10.2473 7.80255Z"
      fill="currentColor"
    />
  </svg>
)
