import React, { forwardRef } from 'react'
import { Link, GatsbyLinkProps } from 'gatsby'

export type ContentfulLink = Queries.LinkFragment | Queries.MenuItemFragment

export type ContentfulLinkProps<T> = Omit<GatsbyLinkProps<T>, 'ref' | 'to'> & {
  link?: ContentfulLink | null
  to?: string
}

/**
 * Return link.page.slug or link.url in this order
 *
 * @todo Temporary, will find a better solution for this, but need to sleep on it.
 */
const ContentfulLink = forwardRef<any, ContentfulLinkProps<any>>(
  function ContentfulLink(cprops, forwaredRef) {
    const { children, link, to, activeClassName, ...props } = cprops
    const linkProps = { ...props, ref: forwaredRef }
    // explicitly passed in to prop
    if (to) {
      return (
        <Link {...linkProps} activeClassName={activeClassName} to={to}>
          {children}
        </Link>
      )
    }
    const url = link && getLinkUrl(link)
    if (!url) {
      // no url,
      return null
    } else if (url.startsWith('mailto:')) {
      // mailto anchor
      return (
        <a {...linkProps} href={url}>
          {children || link.title}
        </a>
      )
    } else if (url.startsWith('http://') || url.startsWith('https://')) {
      // external url, render html anchor
      return (
        <a {...linkProps} href={url} target="_blank">
          {children || link.title}
        </a>
      )
    } else {
      // internal url, render gatsby link
      return (
        <Link {...linkProps} activeClassName={activeClassName} to={url}>
          {children || link.title}
        </Link>
      )
    }
  }
)

export { ContentfulLink }

export const getLinkUrl = (link: ContentfulLink) => {
  if (link?.page) {
    const { slug } = link.page
    return slug === 'homepage' ? '/' : `/${slug}/`
  }
  if (link?.url) {
    return link.url
  }
  return null
}
