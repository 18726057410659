import React from 'react'

type Props = React.ComponentProps<'svg'>

export const PlusIcon = (props: Props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 0H8V8H0V12H8V20H12V12H20V8H12V0Z"
    />
  </svg>
)
