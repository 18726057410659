import React from 'react'
import clsx from 'clsx'

import * as styles from './subtitle.module.scss'

type Color = 'light'

type Props = React.ComponentProps<'h3'> & {
  color?: Color | null
  nomargin?: boolean
  Component?: 'h2' | 'h3'
}

export const Subtitle = ({
  className,
  children,
  color,
  nomargin,
  Component = 'h3',
  ...props
}: Props) => (
  <Component
    className={clsx(
      styles.subtitle,
      className,
      color && styles[color],
      nomargin && styles.nomargin
    )}
    {...props}
  >
    {children}
  </Component>
)
