import React from 'react'

type Props = React.ComponentProps<'svg'>

export const TwitterIcon = (props: Props) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.03855 19.7433C15.0808 19.7433 19.4815 13.0741 19.4815 7.30038C19.4815 7.11276 19.4815 6.92513 19.473 6.73751C20.3258 6.12346 21.0678 5.34738 21.6562 4.46895C20.8716 4.81862 20.0273 5.04888 19.1404 5.15975C20.0444 4.62246 20.7352 3.76109 21.0678 2.73768C20.2235 3.24086 19.2854 3.59905 18.2875 3.79521C17.4859 2.94237 16.3516 2.4136 15.0979 2.4136C12.6844 2.4136 10.7228 4.37514 10.7228 6.78868C10.7228 7.12982 10.7655 7.46242 10.8337 7.7865C7.20059 7.60741 3.97685 5.85908 1.81917 3.21528C1.44392 3.86343 1.2307 4.61393 1.2307 5.41561C1.2307 6.93366 2.00679 8.27262 3.17518 9.05724C2.4588 9.03165 1.78505 8.8355 1.19659 8.51142C1.19659 8.52848 1.19659 8.54553 1.19659 8.57112C1.19659 10.6862 2.70612 12.4601 4.70177 12.8609C4.33505 12.9633 3.95127 13.0144 3.55043 13.0144C3.269 13.0144 2.99609 12.9888 2.73171 12.9377C3.28605 14.6775 4.90645 15.9397 6.81681 15.9738C5.31581 17.1507 3.43104 17.85 1.38422 17.85C1.03455 17.85 0.684886 17.833 0.34375 17.7903C2.27117 19.0184 4.57384 19.7433 7.03855 19.7433Z"
      fill="currentColor"
    />
  </svg>
)
