import React from 'react'

type Props = React.ComponentProps<'svg'>

export const DiscordIcon = (props: Props) => (
  <svg
    width="22"
    height="18"
    viewBox="0 0 22 18"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M0 12.7063C0 12.5947 0 12.4822 0 12.3705C0.0207059 12.2203 0.0543529 12.0708 0.0586667 11.9197C0.158745 8.46695 1.28808 5.34157 3.22149 2.47222C3.31122 2.33874 3.43718 2.18512 3.58039 2.13643C4.70541 1.75782 5.82698 1.36075 6.97271 1.05182C7.92863 0.794099 7.95451 0.839431 8.35137 1.70997C8.46094 1.9509 8.58949 1.98616 8.84141 1.97777C10.0743 1.93663 11.3097 1.88123 12.5417 1.91564C13.4838 1.94167 13.4899 2.00211 13.9307 1.18194C14.0541 0.95276 14.1732 0.836073 14.4605 0.924218C15.7727 1.32801 17.091 1.71333 18.4015 2.11964C18.5369 2.16161 18.6681 2.28586 18.7509 2.40506C20.1071 4.36189 21.0907 6.48074 21.5738 8.79939C21.7619 9.70099 21.8602 10.6194 22 11.5302V13.5458C21.9715 13.7658 21.9569 13.9882 21.9085 14.204C21.8801 14.3282 21.8275 14.4818 21.7308 14.5507C20.1563 15.6773 18.4395 16.536 16.5699 17.0935C16.4724 17.1228 16.2852 17.096 16.2403 17.0305C15.8642 16.4798 15.5122 15.9148 15.1567 15.3599C15.8141 15.0476 16.4016 14.7681 17.0107 14.4785C16.7744 14.1788 16.5897 14.0512 16.2403 14.1981C12.76 15.6596 9.2702 15.6479 5.78729 14.2065C5.43443 14.0604 5.23686 14.1653 5.00824 14.481C5.62941 14.7765 6.21694 15.0569 6.85882 15.3616C6.50682 15.9131 6.17294 16.4638 5.80196 16.9894C5.74157 17.075 5.49827 17.1077 5.36973 17.0691C3.57176 16.5268 1.91443 15.7058 0.398588 14.622C0.251922 14.5171 0.135451 14.2988 0.109569 14.1192C0.0422745 13.6524 0.0327843 13.1781 0 12.7063H0ZM16.6475 9.80928C16.6449 8.18321 15.0929 7.16408 13.7478 7.90618C12.6591 8.50725 12.339 10.1157 13.1016 11.1508C13.8238 12.1296 15.0126 12.3 15.9056 11.5327C16.431 11.0811 16.6596 10.4901 16.6475 9.81012V9.80928ZM9.34698 9.82943C9.35216 8.78679 8.74478 7.95739 7.79404 7.70974C6.97702 7.49651 6.10737 7.91289 5.64494 8.73894C5.15231 9.61872 5.36455 10.8805 6.11773 11.5168C6.69922 12.0079 7.36353 12.1741 8.07702 11.8559C8.95271 11.4664 9.32541 10.7445 9.34698 9.82943Z" />
  </svg>
)
