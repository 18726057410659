// extracted by mini-css-extract-plugin
export var activeButton = "header-navigation-module--activeButton--6dc4c";
export var activeLink = "header-navigation-module--activeLink--3e14c";
export var activeParent = "header-navigation-module--activeParent--8cd9e";
export var dappButton = "header-navigation-module--dappButton--f9b1a";
export var link = "header-navigation-module--link--de50d";
export var list = "header-navigation-module--list--c5dba";
export var nav = "header-navigation-module--nav--8a5a6";
export var submenu = "header-navigation-module--submenu--3e997";
export var submenuItem = "header-navigation-module--submenuItem--f3733";
export var submenuLink = "header-navigation-module--submenuLink--6711c";