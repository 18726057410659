import React, { useCallback, useEffect } from 'react'
import clsx from 'clsx'
import { Link, Script } from 'gatsby'
import Consent, { getCookieConsentValue } from 'react-cookie-consent'
import { useCookieConsent } from './cookie-consent-context'
import { useSnippets } from '../snippet'

import * as styles from './cookie-consent.module.scss'
import * as bstyles from '../button/link-button.module.scss'

export const CookieConsent = () => {
  const s = useSnippets()
  const [consent, setConsent] = useCookieConsent()
  const acceptCookies = useCallback(() => setConsent(true), [])
  const declineCookies = useCallback(() => setConsent(false), [])

  useEffect(() => {
    // apply cookie consent value on mount
    const value = getCookieConsentValue()
    if (value && value === 'true') {
      setConsent(true)
    }
  }, [])

  return (
    <div className={styles.consent}>
      <Consent
        onAccept={acceptCookies}
        onDecline={declineCookies}
        // text
        buttonText={s.cookie_consent_accept_text}
        declineButtonText={s.cookie_consent_decline_text}
        ariaAcceptLabel={s.cookie_consent_accept_label}
        ariaDeclineLabel={s.cookie_consent_decline_label}
        // core styles
        disableStyles
        containerClasses={styles.container}
        contentClasses={styles.content}
        // button styles
        disableButtonStyles
        enableDeclineButton
        buttonWrapperClasses={styles.buttons}
        buttonClasses={clsx(bstyles.button, bstyles.fill, bstyles.light)}
        declineButtonClasses={clsx(bstyles.button, bstyles.secondary)}
      >
        {s.cookie_consent_text}{' '}
        <Link to="/privacy-policy/">{s.cookie_consent_privacy_policy}</Link>.
      </Consent>
      {consent && (
        <>
          {/**
           * Enable google tagmanager
           *
           * @todo check if we need to set send_page_view to true
           *
           * @see gatsby-browser.tsx
           * @see https://paulie.dev/posts/2022/07/how-to-use-gatsbys-script-api-with-google-analytics/
           */}
          <Script
            src={`https://www.googletagmanager.com/gtag/js?id=${process.env.GATSBY_GOOGLE_TAGMANAGER_ID}`}
            strategy="post-hydrate"
            forward={[`gtag`]}
          />
          <Script
            id="gtag-config"
            strategy="post-hydrate"
            dangerouslySetInnerHTML={{
              __html: `window.dataLayer = window.dataLayer || [];
              window.gtag = function gtag(){ window.dataLayer.push(arguments);}
              gtag('js', new Date()); 
              gtag('config', '${process.env.GATSBY_GOOGLE_TAGMANAGER_ID}', { send_page_view: false })`,
            }}
          />
        </>
      )}
    </div>
  )
}
