import React from 'react'
import clsx from 'clsx'
import {
  GatsbyImage,
  GatsbyImageProps,
  IGatsbyImageData,
  getImage,
} from 'gatsby-plugin-image'

type Image = {
  id: string
  title: string | null
  gatsbyImageData: IGatsbyImageData | null
  [key: string]: any
}

type Props = Omit<GatsbyImageProps, 'image'> & {
  image?: Image | null
}

export const Image = ({ className, image, ...props }: Props) => {
  const img = image && getImage(image)
  if (!img) return null
  return <GatsbyImage image={img} className={clsx(className)} {...props} />
}
