import React from 'react'
import clsx from 'clsx'

import * as styles from './plain-image.module.scss'

type Props = React.ComponentProps<'img'> & {
  image?: Queries.SvgImageFragment | null
}

export const PlainImage = ({ className, image, alt, ...props }: Props) =>
  image?.file?.url ? (
    <img
      className={clsx(styles.image, className)}
      src={image.file?.url}
      alt={alt || image?.title!}
      width={image.file.details?.image?.width!}
      height={image.file.details?.image?.height!}
      loading="lazy"
      {...props}
    />
  ) : null
