import React, { createContext, useContext } from 'react'

export type Brand = Queries.BrandFragment

const defaultBrand: Brand = {
  id: '-1',
  name: 'NFTfi',
  email: '',
  siteUrl: '',
  description: null,
  keywords: null,
  image: null,
  logo: null,
  footerLogo: null,
  dAppUrl: '',
  dAppText: '',
  twitterUrl: '',
  twitterIcon: null,
  discordUrl: '',
  discordIcon: null,
  announcementColor: '',
  announcementShow: false,
  announcementText: null,
}

export const BrandContext = createContext<Brand>(defaultBrand)

type Props = {
  children: React.ReactNode
  brand?: Brand | null
}

export const BrandProvider = ({ children, brand }: Props) => {
  return (
    <BrandContext.Provider value={brand || defaultBrand}>
      {children}
    </BrandContext.Provider>
  )
}

export const useBrand = () => {
  const context = useContext(BrandContext)
  if (!context) {
    throw new Error('Missing Brand Context!')
  }
  return context
}
