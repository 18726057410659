import React from 'react'
import clsx from 'clsx'
import { ContentfulLink, ContentfulLinkProps } from '../contentful-link'

import * as styles from './link-button.module.scss'

type Color = 'primary' | 'secondary' | 'light'
type Variant = 'fill' | 'border'
type Size = 'lg'

type Props<T> = ContentfulLinkProps<T> & {
  href?: string
  color?: Color | null
  size?: Size | null
  variant?: Variant | null
}

export const LinkButton = <T,>({
  className,
  color,
  size,
  variant,
  ...props
}: Props<T>) => {
  const Component = props.href ? 'a' : ContentfulLink
  return (
    <Component
      {...props}
      className={clsx(
        styles.button,
        color && styles[color],
        size && styles[size],
        variant && styles[variant],
        className
      )}
    />
  )
}
