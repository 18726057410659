import React from 'react'
import clsx from 'clsx'
import { ContentfulLink, ContentfulLinkProps } from '../contentful-link'

import * as styles from './link.module.scss'

type Color = 'light'

type Props<T> = ContentfulLinkProps<T> & {
  color?: Color | null
  to?: string | null
  link?: ContentfulLink | null
}

export const Link = <T,>({ className, color, ...props }: Props<T>) => {
  return (
    <ContentfulLink
      className={clsx(styles.link, className, color && styles[color])}
      {...props}
    />
  )
}
