import React, { useCallback, useRef } from 'react'
import clsx from 'clsx'
import { Disclosure } from '@headlessui/react'
import { ContentfulLink } from '../contentful-link'
import { LinkButton } from '../button'
import { ArrowIcon } from '../icons'

import * as styles from './drawer-navigation.module.scss'
import { useActiveParentId } from './useActiveParentId'

type Props = React.ComponentProps<'ul'> & {
  path: string
  brand: Queries.BrandFragment
  items?: Queries.MenuHeaderTreeFragment['items']
  close?: () => void
}

export const DrawerNavigation = ({
  path,
  className,
  brand,
  items,
  close,
  ...props
}: Props) => {
  const listRef = useRef<HTMLUListElement>(null)

  /**
   * Find active parent whose child matches page slug.
   */
  const activeParentId = useActiveParentId({ path, items })

  /**
   * Close open disclosures
   *
   * @see https://github.com/tailwindlabs/headlessui/discussions/475
   */
  const handleButtonClick = useCallback<
    React.MouseEventHandler<HTMLButtonElement>
  >((e) => {
    const items = listRef.current?.querySelectorAll<HTMLButtonElement>(
      'button[aria-expanded=true]'
    )
    items?.forEach((item) => {
      if (item !== e.currentTarget) {
        item.click()
      }
    })
  }, [])

  return (
    <ul className={clsx(styles.list, className)} {...props} ref={listRef}>
      {items?.map((item) =>
        !item?.items ? (
          <li key={item?.id}>
            <ContentfulLink
              link={item}
              className={styles.link}
              activeClassName={styles.active}
              onMouseDown={close}
            >
              {item?.title}
            </ContentfulLink>
          </li>
        ) : (
          <Disclosure
            as="li"
            key={item?.id}
            defaultOpen={activeParentId === item.id}
          >
            <Disclosure.Button
              className={clsx(styles.link, styles.button)}
              onClick={handleButtonClick}
            >
              {item.title}
              <ArrowIcon />
            </Disclosure.Button>
            <Disclosure.Panel as="ul" className={styles.submenu}>
              {item.items.map((subItem) => (
                <li key={subItem?.id}>
                  <ContentfulLink
                    link={subItem}
                    className={styles.link}
                    activeClassName={styles.active}
                    onClick={close}
                  >
                    {subItem?.title}
                  </ContentfulLink>
                </li>
              ))}
            </Disclosure.Panel>
          </Disclosure>
        )
      )}
      <li className={styles.dappButton}>
        <LinkButton
          href={brand?.dAppUrl!}
          onClick={close}
          color="primary"
          variant="fill"
          target="_blank"
        >
          {brand?.dAppText}
        </LinkButton>
      </li>
    </ul>
  )
}
