import React, { createContext, useContext, useState } from 'react'

export type CookieConsentContextType = [
  value: boolean,
  setContext: React.Dispatch<React.SetStateAction<boolean>>
]

export const CookieConsentContext = createContext<CookieConsentContextType>([
  false,
  () => undefined,
])

export type CookieConsentProviderProps = {
  children: React.ReactNode
  initialValue?: boolean
}

export const CookieConsentProvider = ({
  children,
  initialValue = false,
}: CookieConsentProviderProps) => {
  const value = useState(initialValue)
  return (
    <CookieConsentContext.Provider value={value}>
      {children}
    </CookieConsentContext.Provider>
  )
}

export const useCookieConsent = () => {
  const context = useContext(CookieConsentContext)
  if (!context) {
    throw new Error('Missing Cookie Consent Context!')
  }
  return context
}
