import React, { forwardRef } from 'react'
import clsx from 'clsx'

import * as styles from './section.module.scss'

type Color =
  | 'hero'
  | 'dark'
  | 'lightblue'
  | 'howitworks'
  | 'ambassadorsroles'
  | 'ambassadorsmore'
  | 'topcollections'

type Props = Omit<React.ComponentProps<'div'>, 'color'> & {
  variant?: Color | null
  first?: boolean
}

export const Section = forwardRef<HTMLDivElement, Props>(function Section(
  { className, children, variant, first, ...props },
  ref
) {
  return (
    <section
      className={clsx(
        styles.section,
        className,
        variant && styles[variant],
        first && styles.first
      )}
      ref={ref}
      {...props}
    >
      {children}
    </section>
  )
})
