import '@fontsource/lato'
import '@fontsource/roboto'
import '../styles/global.scss'
import React, { useEffect, useMemo } from 'react'
import { useErrorBoundary } from 'use-error-boundary'
import { PageProps, graphql, useStaticQuery } from 'gatsby'
import { BrandProvider } from '../components/brand'
import { SnippetsProvider, Snippets } from '../components/snippet'
import { HomepageBanner } from '../components/homepage-banner'
import { MailerLiteScript } from '../components/mailerlite'
import { Header } from '../components/header'
import { Footer } from '../components/footer'
import {
  CookieConsent,
  CookieConsentProvider,
} from '../components/cookie-consent'

type PageContext = {
  slug: string
}

export const Layout = ({
  children,
  path,
  pageContext,
}: PageProps<any, PageContext>) => {
  const { ErrorBoundary, didCatch, error } = useErrorBoundary()
  if (didCatch) {
    console.log('Application error', error)
  }

  const data = useStaticQuery<Queries.LayoutStaticQuery>(graphql`
    query LayoutStatic {
      brand: contentfulBrand(slug: { eq: "nftfi" }) {
        ...Brand
      }
      snippets: allContentfulSnippet {
        nodes {
          ...Snippet
        }
      }
    }
  `)

  const brand = data.brand as Queries.BrandFragment

  const snippets = useMemo(
    () =>
      data.snippets.nodes.reduce((obj, item) => {
        if (item.key && item.value?.value) {
          obj[item.key!] = item.value?.value!
        }
        return obj
      }, {} as Snippets),
    []
  )

  return (
    <ErrorBoundary>
      <CookieConsentProvider>
        <BrandProvider brand={brand}>
          <SnippetsProvider snippets={snippets}>
            {pageContext.slug === 'homepage' && (
              <HomepageBanner brand={brand} />
            )}
            <Header path={path} />
            {children}
            <Footer />
            <CookieConsent />
            <MailerLiteScript />
          </SnippetsProvider>
        </BrandProvider>
      </CookieConsentProvider>
    </ErrorBoundary>
  )
}

export default Layout
