import React from 'react'

type Props = React.ComponentProps<'svg'>

export const MinusIcon = (props: Props) => (
  <svg
    width="20"
    height="4"
    viewBox="0 0 20 4"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M0 4V0H20V4H0Z" />
  </svg>
)
