// extracted by mini-css-extract-plugin
export var ambassadorsmore = "background-module--ambassadorsmore--d7456";
export var ambassadorsroles = "background-module--ambassadorsroles--fbcdd";
export var background = "background-module--background--90f64";
export var blog = "background-module--blog--b5b32";
export var bottom = "background-module--bottom--31137";
export var dontmissout = "background-module--dontmissout--c50c0";
export var ellipse = "background-module--ellipse--b0eda";
export var hero = "background-module--hero--bd08c";
export var hidepattern = "background-module--hidepattern--a1c57";
export var joinus = "background-module--joinus--a8b67";
export var testimonials = "background-module--testimonials--caa75";
export var topcollections = "background-module--topcollections--b0545";
export var whynftfi = "background-module--whynftfi--4f060";