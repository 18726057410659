import React from 'react'
import clsx from 'clsx'

import * as styles from './text.module.scss'

type Color = 'light' | 'lighter'

type Props = React.ComponentProps<'p'> & {
  center?: boolean
  color?: Color | null
  nomargin?: boolean
}

export const Text = ({
  className,
  children,
  center,
  color,
  nomargin,
  ...props
}: Props) => (
  <p
    className={clsx(
      styles.text,
      className,
      center && styles.center,
      color && styles[color],
      nomargin && styles.nomargin
    )}
    {...props}
  >
    {children}
  </p>
)
