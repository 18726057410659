import React from 'react'
import clsx from 'clsx'

import * as styles from './link-button.module.scss'

type Color = 'primary' | 'secondary' | 'light'
type Variant = 'fill' | 'border'
type Size = 'lg'

type Props<T> = React.ComponentProps<'button'> & {
  color?: Color | null
  size?: Size | null
  variant?: Variant | null
}

export const Button = <T,>({
  className,
  children,
  color,
  size,
  variant,
  ...props
}: Props<T>) => {
  return (
    <button
      {...props}
      className={clsx(
        styles.button,
        color && styles[color],
        size && styles[size],
        variant && styles[variant],
        className
      )}
    >
      {children}
    </button>
  )
}
