import React, { useCallback, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import clsx from 'clsx'
import { useBrand } from '../brand'

import * as styles from './drawer.module.scss'

/*
 * Read the blog post here:
 * https://letsbuildui.dev/articles/building-a-drawer-component-with-react-portals
 */

const IS_CLIENT = typeof document !== 'undefined'

export const DRAWER_PORTAL_ID = 'drawer-portal'

const useMountTransition = (isMounted: boolean, unmountDelay: number) => {
  const [isTransitioning, setIsTransitioning] = useState(false)

  useEffect(() => {
    let timeoutId: NodeJS.Timeout

    if (isMounted && !isTransitioning) {
      setIsTransitioning(true)
    } else if (!isMounted && isTransitioning) {
      timeoutId = setTimeout(() => setIsTransitioning(false), unmountDelay)
    }
    return () => {
      clearTimeout(timeoutId)
    }
  }, [unmountDelay, isMounted, isTransitioning])

  return isTransitioning
}

const addOrientationChangeListener = (fn: () => void) => {
  if (window.screen.orientation && 'onchange' in window.screen.orientation) {
    window.screen.orientation.addEventListener('change', fn)
  } else if ('onorientationchange' in window) {
    window.addEventListener('orientationchange', fn)
  }
}

const removeOrientationChangeListener = (fn: () => void) => {
  if (window.screen.orientation && 'onchange' in window.screen.orientation) {
    window.screen.orientation.removeEventListener('change', fn)
  } else if ('onorientationchange' in window) {
    window.removeEventListener('orientationchange', fn)
  }
}

type Props = {
  children?: React.ReactNode
  className?: string
  containerClassName?: string
  backdropClassName?: string
  isOpen: boolean
  onClose: () => void
}

const root = IS_CLIENT ? document.getElementById(DRAWER_PORTAL_ID) : null

export const Drawer = ({
  children,
  className,
  containerClassName,
  backdropClassName,
  isOpen,
  onClose,
}: Props) => {
  const isTransitioning = useMountTransition(isOpen, 300)

  const brand = useBrand()

  // Append portal root on mount
  useEffect(() => {
    const html = document.querySelector('html')
    const body = document.querySelector('body')
    if (!html || !body || !root) return
    body.appendChild(root)
    // Clean up the portal when drawer component unmounts
    return () => {
      root.remove()
      html.style.overflow = ''
    }
  }, [])

  // Prevent page scrolling when the drawer is open
  useEffect(() => {
    const html = document.querySelector('html')
    if (html) html.style.overflow = isOpen ? 'hidden' : ''
  }, [isOpen])

  // Allow Escape key to dismiss the drawer
  const onKeyUpRef = useRef(false)
  const onKeyUp = useCallback((e: KeyboardEvent) => {
    if (e.key === 'Escape') onClose()
  }, [])
  useEffect(() => {
    if (isOpen && !onKeyUpRef.current) {
      window.addEventListener('keyup', onKeyUp)
      onKeyUpRef.current = true
    }
    if (!isOpen && onKeyUpRef.current) {
      window.removeEventListener('keyup', onKeyUp)
      onKeyUpRef.current = false
    }
  }, [isOpen, onClose])
  useEffect(
    () => () => {
      if (onKeyUpRef.current) {
        window.removeEventListener('keyup', onKeyUp)
        onKeyUpRef.current = false
      }
    },
    []
  )

  // Orientation change
  const onOrientationChange = useCallback(() => {
    setTimeout(() => {
      if (window.innerWidth > 992) {
        onClose()
      }
    }, 120)
  }, [])
  useEffect(() => {
    addOrientationChangeListener(onOrientationChange)
    return () => {
      removeOrientationChangeListener(onOrientationChange)
    }
  }, [])

  if (!root || (!isTransitioning && !isOpen)) {
    return null
  }

  return createPortal(
    <div
      aria-hidden={!isOpen}
      className={clsx(
        styles.container,
        isOpen && styles.open,
        isTransitioning && styles.enter,
        brand.announcementShow && styles.announcement,
        containerClassName
      )}
    >
      <div className={clsx(styles.drawer, className)} role="dialog">
        {children}
      </div>
      <div
        className={clsx(styles.backdrop, backdropClassName)}
        onClick={onClose}
      />
    </div>,
    root
  )
}
