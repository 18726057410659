import React from 'react'

type Props = React.ComponentProps<'svg'>

export const SearchIcon = (props: Props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 19.5932L15.8809 14.4741C16.7868 13.2746 17.324 11.781 17.324 10.162C17.324 6.20654 14.1175 3 10.162 3C6.20654 3 3 6.20654 3 10.162C3 14.1175 6.20654 17.324 10.162 17.324C11.781 17.324 13.2746 16.7868 14.4741 15.8809L19.5932 21L21 19.5932ZM15.3346 10.162C15.3346 13.0187 13.0187 15.3346 10.162 15.3346C7.30527 15.3346 4.98944 13.0187 4.98944 10.162C4.98944 7.30527 7.30527 4.98944 10.162 4.98944C13.0187 4.98944 15.3346 7.30527 15.3346 10.162Z"
    />
  </svg>
)
