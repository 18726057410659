import React, { useMemo } from 'react'
import { Link } from 'gatsby'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { INLINES } from '@contentful/rich-text-types'
import { Options } from '@contentful/rich-text-react-renderer'

import * as styles from './homepage-banner.module.scss'

const options: Options = {
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => {
      const uri: string = node.data.uri
      if (uri.startsWith('http://') || uri.startsWith('https://')) {
        // external link
        return (
          <a href={uri} target="_blank" style={{ textOverflow: 'ellipsis' }}>
            {children}
          </a>
        )
      } else {
        // internal link
        return <Link to={uri}>{children}</Link>
      }
    },
  },
}

enum Color {
  pink = '#cb2b83',
  yellow = '#e4b80b',
  green = '#28b440',
  violet = '#7e5bd6',
  default = '#7e5bd6',
}

const announcementColor = (brand?: Queries.BrandFragment) => {
  const color = brand?.announcementColor as Color
  return color && Object.keys(Color).includes(color)
    ? (Color as any)[color]
    : Color.default
}

type Props = {
  brand?: Queries.BrandFragment
}

export const HomepageBanner = ({ brand }: Props) => {
  if (!brand?.announcementShow) {
    return null
  }

  return (
    <div
      className={styles.announcement}
      style={{ backgroundColor: announcementColor(brand) }}
    >
      {renderRichText(brand?.announcementText as any, options)}
    </div>
  )
}
