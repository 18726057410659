import React from 'react'
import clsx from 'clsx'

import * as styles from './buttons.module.scss'

type Props = React.ComponentProps<'div'> & {
  center?: boolean
}

export const Buttons = ({ className, children, center, ...props }: Props) => {
  return (
    <div
      className={clsx(styles.buttons, className, center && styles.center)}
      {...props}
    >
      {children}
    </div>
  )
}
