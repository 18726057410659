import React from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import { ContentfulLink } from '../contentful-link'
import { Container } from '../page'
import { PlainImage } from '../image'
import { useBrand } from '../brand'
import { useSnippets } from '../snippet'

import * as styles from './footer.module.scss'

export const Footer = () => {
  const data = useStaticQuery<Queries.FooterStaticQuery>(graphql`
    query FooterStatic {
      menus: allContentfulMenu(
        filter: { slug: { nin: ["header", "header-tree"] } }
        sort: { order: ASC }
      ) {
        nodes {
          ...Menu
        }
      }
    }
  `)

  const brand = useBrand()
  const s = useSnippets()

  return (
    <footer className={styles.footer}>
      <div className={styles.wrapper}>
        <Container className={styles.container}>
          <div className={styles.info}>
            <Link to="/">
              <PlainImage
                image={brand?.footerLogo}
                alt={brand?.footerLogo?.title!}
              />
            </Link>
            <p>{s.footer_text}</p>
          </div>
          <nav className={styles.menu}>
            {data.menus.nodes.map((menu) => (
              <ul key={menu.id}>
                <li>
                  <h3>{menu.title}</h3>
                </li>
                {menu.items?.map((item) => (
                  <li key={item?.id}>
                    <ContentfulLink link={item}>{item?.title}</ContentfulLink>
                  </li>
                ))}
              </ul>
            ))}
            <ul className={styles.social}>
              <li>
                <a href={brand?.twitterUrl!} target="_blank">
                  <PlainImage
                    image={brand?.twitterIcon}
                    alt={brand?.twitterIcon?.title!}
                  />
                  {brand?.twitterIcon?.title!}
                </a>
              </li>
              <li>
                <a href={brand?.discordUrl!} target="_blank">
                  <PlainImage
                    image={brand?.discordIcon}
                    alt={brand?.discordIcon?.title!}
                  />
                  {brand?.discordIcon?.title!}
                </a>
              </li>
            </ul>
          </nav>
        </Container>
      </div>
    </footer>
  )
}

export default Footer
