import React from 'react'
import clsx from 'clsx'

import * as styles from './background.module.scss'

type Variant =
  | 'hero'
  | 'blog'
  | 'ellipse'
  | 'bottom'
  | 'joinus'
  | 'whynftfi'
  | 'dontmissout'
  | 'ambassadorsmore'
  | 'ambassadorsroles'
  | 'testimonials'
  | 'topcollections'

type Props = React.ComponentProps<'div'> & {
  variant: Variant
  pattern?: boolean
}

// variants with three highlighs need an extra div
const tertiary = [
  'hero',
  'blog',
  'ellipse',
  'whynftfi',
  'testimonials',
  'topcollections',
]

export const Background = ({
  className,
  children,
  variant,
  pattern = true,
  ...props
}: Props) => (
  <div
    className={clsx(
      styles.background,
      variant && styles[variant],
      pattern === false && styles.hidepattern,
      className
    )}
    {...props}
  >
    {children}
    {tertiary.indexOf(variant) > -1 && <div />}
  </div>
)
