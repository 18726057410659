import React from 'react'
import clsx from 'clsx'

import * as styles from './container.module.scss'

type Props = React.ComponentProps<'div'> & {
  fluid?: boolean
}

export const Container = ({ className, children, fluid, ...props }: Props) => (
  <div
    className={clsx(
      styles.container,
      fluid ? styles.fluid : styles.constrained,
      className
    )}
    {...props}
  >
    {children}
  </div>
)
