import React from 'react'

type Props = React.ComponentProps<'svg'>

export const ArrowIcon = (props: Props) => (
  <svg
    width="12"
    height="20"
    viewBox="0 0 12 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.50126 19.7828L0.0664313 18.348L8.41445 10L0.0664311 1.65199L1.50126 0.217163L11.2827 9.99862L1.50126 19.7828Z"
    />
  </svg>
)
