import React, { useCallback, useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import { graphql, Link, useStaticQuery } from 'gatsby'
import { CloseIcon, MenuIcon } from '../icons'
import { Container } from '../page'
import { PlainImage } from '../image'
import { useBrand } from '../brand'
import { Drawer } from '../drawer'
import { HeaderNavigation, DrawerNavigation } from '../navigation'

import * as styles from './header.module.scss'

type Props = {
  path: string
}

export const Header = ({ path }: Props) => {
  const data = useStaticQuery<Queries.HeaderStaticQuery>(graphql`
    query HeaderStatic {
      menu: contentfulMenu(slug: { eq: "header-tree" }) {
        ...MenuHeaderTree
      }
    }
  `)

  const items = data.menu?.items

  const brand = useBrand()

  const [drawerOpen, setOpen] = useState(false)
  const close = useCallback(() => setOpen(false), [drawerOpen])
  const toggle = useCallback(() => setOpen(!drawerOpen), [drawerOpen, setOpen])

  const [opacity, setOpacity] = useState(0)
  const opacityRef = useRef(0)

  const [scrolled, setScrolled] = useState(false)
  const scrolledRef = useRef(false)

  useEffect(() => {
    const max = 96
    const factor = 2
    const onScroll = () => {
      const { scrollY } = window
      const sy = scrollY < max * factor ? scrollY / factor : max
      const opacity = sy / 100
      if (opacity !== opacityRef.current) {
        opacityRef.current = opacity
        setOpacity(opacity)
      }
      const scrolled = scrollY >= max
      if (scrolled !== scrolledRef.current) {
        scrolledRef.current = scrolled
        setScrolled(scrolled)
      }
    }
    window.addEventListener('scroll', onScroll)
    onScroll()
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [])

  return (
    <>
      <header
        className={clsx(
          styles.header,
          drawerOpen && styles.headerDrawer,
          scrolled && styles.headerScrolled
          //brand.announcementShow && styles.headerDrawerAnnouncement
        )}
        style={{ background: `rgba(22, 21, 45,${opacity})` }}
      >
        <Container className={styles.container}>
          <Link to="/" className={styles.logo}>
            <PlainImage image={brand?.logo} alt={brand?.name!} />
          </Link>
          <HeaderNavigation path={path} brand={brand} items={items} />
          <button
            className={styles.menuButton}
            onClick={toggle}
            aria-label="Menu button"
          >
            {drawerOpen ? <CloseIcon /> : <MenuIcon />}
          </button>
        </Container>
      </header>
      {drawerOpen && <div className={styles.headerFiller} />}
      <Drawer isOpen={drawerOpen} onClose={close}>
        <DrawerNavigation
          path={path}
          brand={brand}
          items={data.menu?.items}
          close={close}
        />
      </Drawer>
    </>
  )
}

export default Header
