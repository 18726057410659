import React from 'react'
import clsx from 'clsx'

import * as styles from './intro.module.scss'

type Color = 'light'

type Props = React.ComponentProps<'p'> & {
  center?: boolean
  narrow?: boolean
  color?: Color | null
}

export const Intro = ({
  className,
  children,
  center,
  narrow,
  color,
  ...props
}: Props) => (
  <p
    className={clsx(
      styles.intro,
      className,
      center && styles.center,
      narrow && styles.narrow,
      color && styles[color]
    )}
    {...props}
  >
    {children}
  </p>
)
