import React from 'react'
import clsx from 'clsx'

import * as styles from './main.module.scss'

type Props = React.ComponentProps<'main'>

export const Main = ({ className, children }: Props) => (
  <main className={clsx(styles.main, className)}>{children}</main>
)
